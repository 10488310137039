
  import { Component, Mixins } from "vue-property-decorator";
  import { bus } from "@/main";
  import HasNotaryModal from "./has-notary-modal";
  import { Mandate } from "@/store/modules/mandate.store";

  @Component
  export default class NotaryUpdateModal extends Mixins(HasNotaryModal) {
    mounted() {
      bus.$off("show-update-notary");
      bus.$on("show-update-notary", async (type: "BUYER" | "SELLER", subject: Activity, initialValue: string) => {
        this.getNotaryOptions();

        this.notaryType = type;

        this.selectedNotary = initialValue;

        this.subject = subject;

        this.show = true;
      });
    }
  }
